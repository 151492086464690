import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dropdown, Flex, Select, Space, Switch } from 'antd';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';
import { FilterOutlined } from '@ant-design/icons';


const ClientProgramBooleanFilters = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {
    
    const [hideInactive, setHideInactive] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    useEffect(()=>{
        onHideInactiveChange(hideInactive)
    }, [hideInactive]);

    const onHideInactiveChange = useCallback((v) => {
        setExtraApiParamsState((p) => {
            if (v === false) {
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s) {
                    s = {}
                }
                if ('is_inactive' in s) {
                    delete s['is_inactive'];
                }
                // console.log({v, s})
                return s;
            } else if (v === true) {
                const s = {
                    ...p,
                    is_inactive: 'False'
                }
                return s;
            }
        })
    }, []);

    const items = [
        {
            label: <Flex gap={5} align='center' justify='end'>
                <span>Hide Inactive?</span>
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={hideInactive}
                    onChange={(v)=>setHideInactive(v)} />
            </Flex>,
            key: '1',
        }
    ];

    const menuProps = {
        items,
        // onClick: handleMenuClick,
    };

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <Dropdown
                    menu={menuProps}
                    open={open}
                    onOpenChange={handleOpenChange}
                >
                    <Button>
                        <Space>
                            <FilterOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Flex>
        </>
    )
}

export default ClientProgramBooleanFilters;