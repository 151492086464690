import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
// import ChannelFilterForReports from 'Components/Dashboard/Reports/Filters/ChannelFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { Typography, Flex, Spin, Table, Switch, Dropdown, Space, Button, Select } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from '../ExportXlsx';
// import PaymentMethodFilterForReports from 'Components/Dashboard/Reports/Filters/PaymentMethodFilterForReports';
import EmployeeFilterForReports from 'Components/Dashboard/Reports/Filters/EmployeeFilterForReports';
// import { LuBadgeHelp } from "react-icons/lu";
// import { LuTicket } from "react-icons/lu";
// import { IoFastFoodOutline } from "react-icons/io5";
import { SettingOutlined } from '@ant-design/icons';
import UsedCouponReport from './UsedCouponReport';
import { getTableColumns, getName, getTime } from './seatsAndFoodUtils';
const { Text } = Typography;

const API_URL = "/reports/cash-register-seats-and-food-report/";
const initialDates = dayjs();

const DownloadOptionsForExcel = {
    "UsedCouponsOnly": "UCO",
    "CashRegisterReportOnly": "CRRO",
    "CashRegisterUsedCouponInOneExcel": "CRUCIE",
    "CashRegisterUsedCouponInSeparateExcel": "CRUCISE"
}

const CashRegisterSeatAndFoodReport = () => {
    const [data, setData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");

    const [employeeFilterVal, setEmployeeFilterVal] = useState("");
    // const [channelFilterVal, setChannelFilterVal] = useState("");
    // const [paymentMethodFilterVal, setPaymentMethodFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const [downloadTicketOnlyReport, setDownloadTicketOnlyReport] = useState(true);
    const [downloadType, setDownloadType] = useState(DownloadOptionsForExcel.CashRegisterReportOnly);

    const [showOnlyClosedSession, setShowOnlyClosedSession] = useState(true);
    const [SimplifiedReporting, setSimplifiedReporting] = useState(true);
    const [open, setOpen] = useState(false);

    const [tableColumns, setTableColumns] = useState([]);

    const [includeCash, setIncludeCash] = useState(true);
    const [includeMips, setIncludeMips] = useState(false);
    const [includeMyt, setIncludeMyt] = useState(true);

    useEffect(() => {
        setTableColumns(getTableColumns({ includeCash: includeCash, includeMIPS: includeMips, includeMYT: includeMyt, downloadTicketOnlyReport: downloadTicketOnlyReport, SimplifiedReporting: SimplifiedReporting }));
    }, [includeCash, includeMips, includeMyt, downloadTicketOnlyReport, SimplifiedReporting]);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['employee'] = employeeFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                payload['only_closed_sessions'] = showOnlyClosedSession;
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data?.map((d, ind) => ({ ...d, key: ind })));
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Cashier Closing Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }

        if (startDate && endDate && theatreFilterVal && employeeFilterVal) {
            fetchData();
        }
    }, [startDate, endDate, theatreFilterVal, openNotification, employeeFilterVal, showOnlyClosedSession]);

    const items = [
        {
            label: <Flex gap={5} align='center' justify='end'>
                <span>Only show closed sessions?</span>
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={showOnlyClosedSession}
                    onChange={(v) => {
                        console.log({ v })
                        setShowOnlyClosedSession(v)
                    }} />
            </Flex>,
            key: '1',
        },
        {
            label: <Flex gap={5} align='center' justify='end'>
                <span>Simplified Reporting</span>
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={SimplifiedReporting}
                    onChange={(v) => {
                        console.log({ v })
                        setSimplifiedReporting(v)
                    }} />
            </Flex>,
            key: '1',
        },
        !SimplifiedReporting && {
            label: <Flex gap={5} vertical align='end' justify='end'>
                <span>Choose payment methods to show</span>
                <Flex gap={5}>
                    <Switch
                        size='small'
                        checkedChildren="Cash"
                        unCheckedChildren="Cash"
                        defaultChecked={includeCash}
                        onChange={(v) => {
                            setIncludeCash(v)
                        }}
                    />
                    <Switch
                        size='small'
                        checkedChildren="my.t"
                        unCheckedChildren="my.t"
                        defaultChecked={includeMyt}
                        onChange={(v) => {
                            setIncludeMyt(v)
                        }}
                    />
                    <Switch
                        size='small'
                        checkedChildren="MIPS"
                        unCheckedChildren="MIPS"
                        defaultChecked={includeMips}
                        onChange={(v) => {
                            setIncludeMips(v)
                        }}
                    />
                </Flex>
            </Flex >,
            key: '3'
        },
        !SimplifiedReporting && {
            label: <Flex vertical gap={10}><Flex gap={5} align='center' justify='center'>
                <span>download only ticket related data?</span>
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={downloadTicketOnlyReport}
                    onChange={(v) => {
                        console.log({ v })
                        setDownloadTicketOnlyReport(v)
                    }} />
            </Flex >
                <Flex gap={5} align='center' justify='end'>
                    <Select
                        defaultValue={DownloadOptionsForExcel.CashRegisterReportOnly}
                        style={{
                            width: "100%",
                        }}
                        onSelect={(v) => setDownloadType(v)}
                        options={[
                            {
                                value: DownloadOptionsForExcel.CashRegisterReportOnly,
                                label: 'Cash Register Report Only',
                            }
                        ]}
                    />
                </Flex >
            </Flex>,
            key: '4',
        }
    ];

    const menuProps = {
        items,
        // onClick: handleMenuClick,
    };

    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        employee: employeeFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                        ticket_only: downloadTicketOnlyReport,
                        only_closed_sessions: showOnlyClosedSession,
                        download_type: downloadType,
                        include_cash: includeCash,
                        include_mips: includeMips,
                        include_myt: includeMyt,
                        simplified_reporting: SimplifiedReporting
                    }}
                    reportName={downloadType === DownloadOptionsForExcel.CashRegisterReportOnly ?
                        `cashier_closing_report_seat_and_food` :
                        downloadType === DownloadOptionsForExcel.UsedCouponsOnly ?
                            `used_coupons_report` : downloadType === DownloadOptionsForExcel.CashRegisterUsedCouponInOneExcel ?
                                `cash_register_with_food_coupons` : `cash_register_and_used_coupons`}
                    reportTitleForNotification={'Cashier Closing Report - Seat and Food'}
                    isZip={downloadType === DownloadOptionsForExcel.CashRegisterUsedCouponInSeparateExcel}
                />
                <Dropdown
                    menu={menuProps}
                    open={open}
                    onOpenChange={handleOpenChange}
                >
                    <Button>
                        <Space>
                            Settings
                            <SettingOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10} id='report-content'>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`Cash Register Closing Report (Seat and Food)`} />
                    <Flex gap={15}>
                        {/* <ChannelFilterForReports channelFilterVal={channelFilterVal} setChannelFilterVal={setChannelFilterVal} /> */}
                        <EmployeeFilterForReports employeeFilterVal={employeeFilterVal} setEmployeeFilterVal={setEmployeeFilterVal} />
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        {/* <PaymentMethodFilterForReports paymentMethodFilterVal={paymentMethodFilterVal} setPaymentMethodFilterVal={setPaymentMethodFilterVal} /> */}
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex vertical gap={20} style={{ flex: 1, overflow: "auto", width: "100%" }}>
                    {
                        loading ? <Spin /> :
                            <Table
                                columns={tableColumns}
                                bordered
                                dataSource={data}
                                size='small'
                                scrollToFirstRowOnChange
                                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                                loading={loading}
                                pagination={false}
                                scroll={{
                                    y: "55vh",
                                    x: "100%"
                                }}
                                expandable={{
                                    expandedRowRender: (record, recordIndex) => <UsedCouponReport record={record} key={record?.id || recordIndex} cashier={record?.employee?.username || getName(record)} />,
                                    rowExpandable: (record) => record?.no_tickets_sold?.CASH?.coupon_data?.[0],
                                }}
                                summary={pageData => {
                                    let _totalCashCount = 0;

                                    // CASH
                                    let cashCountTotal = 0;
                                    let cashAmountTotal = 0;
                                    let cashVouchersUsedTotal = 0;
                                    let cashRefundCountTotal = 0;
                                    let cashRefundAmountTotal = 0;
                                    let cashOnlinePaymentRefundsTotal = 0
                                    let cashCashInHandTotal = 0;

                                    // MYT
                                    let mytCountTotal = 0;
                                    let mytAmountTotal = 0;
                                    let mytRefundCountTotal = 0;
                                    let mytRefundAmountCashTotal = 0;
                                    let mytEffectiveAmountTotal = 0;

                                    // MIPS
                                    let mipsCountTotal = 0;
                                    let mipsAmountTotal = 0;
                                    let mipsRefundCountTotal = 0;
                                    let mipsRefundAmountCashTotal = 0;
                                    let mipsEffectiveAmountTotal = 0;

                                    // Food
                                    // CASH
                                    let foodCashCountTotal = 0;
                                    let foodCashAmountTotal = 0;

                                    // MYT
                                    let foodMytCountTotal = 0;
                                    let foodMytAmountTotal = 0;

                                    // MIPS
                                    let foodMipsCountTotal = 0;
                                    let foodMipsAmountTotal = 0;

                                    console.log({ pageData })
                                    pageData.forEach((row) => {
                                        // CASH
                                        cashCountTotal += row.tickets_sold || 0;
                                        cashAmountTotal += row?.cash_collected || 0;
                                        cashVouchersUsedTotal += row?.vouchers_used || 0;
                                        cashRefundCountTotal += row?.total_cash_refund_count || 0;
                                        cashRefundAmountTotal += row?.total_cash_refund_amount || 0;
                                        cashOnlinePaymentRefundsTotal += row?.total_online_refund_amount || 0;
                                        cashCashInHandTotal += row?.cash_in_hand_final || 0;

                                        // MYT
                                        mytCountTotal += row?.myt?.ticket_sold || 0;
                                        mytAmountTotal += row?.myt?.session_amount || 0;
                                        mytRefundCountTotal += row?.myt?.total_refund_count || 0;
                                        mytRefundAmountCashTotal += row?.myt?.total_refund_amount || 0;
                                        mytEffectiveAmountTotal += row?.myt?.effective_sales || 0;

                                        // MIPS
                                        mipsCountTotal += row?.mips?.ticket_sold || 0;
                                        mipsAmountTotal += row?.mips?.session_amount || 0;
                                        mipsRefundCountTotal += row?.mips?.total_refund_count || 0;
                                        mipsRefundAmountCashTotal += row?.mips?.total_refund_amount || 0;
                                        mipsEffectiveAmountTotal += row?.mips?.effective_sales || 0;

                                        // Food
                                        // CASH
                                        foodCashCountTotal += row.food_items_sold || 0;
                                        foodCashAmountTotal += row?.food_cash_collected || 0;

                                        // MYT
                                        foodMytCountTotal += row?.food_myt?.ticket_sold || 0;
                                        foodMytAmountTotal += row?.food_myt?.session_amount || 0;

                                        // MIPS
                                        foodMipsCountTotal += row?.food_mips?.ticket_sold || 0;
                                        foodMipsAmountTotal += row?.food_mips?.session_amount || 0;
                                    });

                                    return (
                                       <>{!SimplifiedReporting && (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell">Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="table-summary-cell"></Table.Summary.Cell>
                                        
                                                    {includeCash && (
                                                        <>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{cashCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(cashAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{cashVouchersUsedTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{cashRefundCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(cashRefundAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(cashOnlinePaymentRefundsTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(cashCashInHandTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    )}
                                        
                                                    {includeMyt && (
                                                        <>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{mytCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mytAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{mytRefundCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mytRefundAmountCashTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mytEffectiveAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    )}
                                        
                                                    {includeMips && (
                                                        <>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{mipsCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mipsAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{mipsRefundCountTotal}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mipsRefundAmountCashTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                <Text>{getFormattedAmount(mipsEffectiveAmountTotal)}</Text>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    )}
                                        
                                                    {!downloadTicketOnlyReport && (
                                                        <>
                                                            {includeCash && (
                                                                <>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{foodCashCountTotal}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{getFormattedAmount(foodCashAmountTotal)}</Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            )}
                                                            {includeMyt && (
                                                                <>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{foodMytCountTotal}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{getFormattedAmount(foodMytAmountTotal)}</Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            )}
                                                            {includeMips && (
                                                                <>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{foodMipsCountTotal}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell className="table-summary-cell" align="center">
                                                                        <Text>{getFormattedAmount(foodMipsAmountTotal)}</Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Table.Summary.Row>
                                            </>
                                        ) }</> 
                                    );
                                }}
                            />
                    }
                </Flex>
            </Flex >
        </>
    )
}

export default CashRegisterSeatAndFoodReport