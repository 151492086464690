import { getFormattedAmount } from 'Utils/textFormat';
import { Flex, Table } from 'antd';
import React, { useEffect, useState } from 'react';


const TableCell = ({ record, text, r }) => {


}

const GatewayWiseTable = ({ data }) => {

    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [effectiveTotal, setEffectiveTotal] = useState(0);

    useEffect(() => {
        console.log({ gateway_data: data })
        if (typeof data === "object" && data?.data && typeof data.data === "object") {
            setEffectiveTotal(data.total);
            const _columns = [{ key: 'pg', title: "Payment Gateways", dataIndex: "pg" }];
            const gateways = Object.keys(data.data)
            let channels = Object.keys(data.data[gateways[0]])?.map?.(r => r);
            channels.forEach(r => {
                let column = {
                    key: r,
                    dataIndex: r,
                    title: r,
                    // render: (text) => isNaN(text) ? text : getFormattedAmount(text) || text
                    render: (text, record) => {
                        let style = {}
                        let print_text = isNaN(text) ? "" : getFormattedAmount(text) || text

                        if (r === "Cash Refunds for Payment Gateways") {
                            if (record.pg === "Cash") {
                                style = {
                                    "fontWeight": "bold",
                                    "textAlign": "right",
                                    "borderTop": "1px dashed black",
                                    "borderBottom": "1px dashed black"
                                }
                            }
                        }

                        return {
                            props: {
                                style: { background: isNaN(text) ? "lightgray" : "white", ...style }
                            },
                            children: <div>
                            {print_text}
                        </div>
                        };
                    }
                }



                _columns.push(column)
            });
            const _tableData = [];

            console.log({ channels, gateways, _columns });
            setColumns(_columns)

            for (let gateway of gateways) {
                let obj = {
                    pg: gateway !== "NA" ? gateway : "Cash"
                }
                for (let channel of channels) {
                    obj[channel] = data.data[gateway][channel]
                }
                _tableData.push(obj)
            }
            setTableData(_tableData);
        }
    }, [data]);


    return (
        <Table
            columns={columns}
            bordered
            dataSource={tableData}
            size='small'
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            pagination={false}
            summary={pageData => {
                let _totals = {

                }
                pageData.forEach((a) => {
                    console.log(a);
                    for (let key in a) {
                        if (key !== "pg") {
                            let n = isNaN(a[key]) ? 0 : Number(a[key]);
                            if (key in _totals) {
                                _totals[key] += n;
                            } else {
                                _totals[key] = n;
                            }
                        }
                    }
                });

                return (
                    <>
                        <Table.Summary.Row >
                            <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                            {
                                Object.keys(_totals)?.map?.((_t, ind) => <Table.Summary.Cell className='table-summary-cell' align='left'>
                                    {
                                        ind !== 5 ? <>
                                            {
                                                ind === 6 ? <>
                                                    <p style={{ margin: 0 }}>
                                                        Effective Total
                                                    </p>
                                                </> : ind === 7 ? <>
                                                    <p style={{ margin: 0 }}>{getFormattedAmount(effectiveTotal)}</p>
                                                </> :
                                                    <p style={{ margin: 0 }}>{getFormattedAmount(_totals[_t])}</p>
                                            }
                                        </> : <></>
                                    }
                                </Table.Summary.Cell>)
                            }
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    )
}

export default GatewayWiseTable;