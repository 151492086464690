import { Col, Flex, Form, Input, Row } from 'antd';
import { fetchProgramTypeSurcharges, fetchSeatTypeList } from 'Api/commonApis';
import { getSurchargeName } from 'Components/CreateShow/utils';
import { useNotificationContext } from 'Config/NotificationsContext';
import React, { useEffect, useState } from 'react'
import { validateTwoDecimalNumber } from 'Utils/formValidators';

const ProgramTypeSurchargeForm = ({ selectedFormatType, showId, _seatTypes = null, _setSeatTypes = null }) => {

    const openNotification = useNotificationContext();
    const [seatTypes, setSeatTypes] = useState(_seatTypes);
    // const [alreadySetSurcharges, setAlreadySetSurcharges] = useState(null);

    useEffect(() => {
        const fetchSeatTypesList = async () => {
            try {
                const list = await fetchSeatTypeList();
                console.log({ list });
                if (Array.isArray(list)) {
                    setSeatTypes(list);
                } else {
                    throw new Error("Fetch SeatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Seat Type Options", err.message, "error");
                } else {
                    openNotification("Seat Type Options", "Something went wrong while fetching format type options", "error")
                }
            }
        }

        // const fetchPresentSurcharges = async (_show_id) => {
        //     try {
        //         const list = await fetchProgramTypeSurcharges(_show_id);
        //         console.log({ list });
        //         if (Array.isArray(list)) {
        //             setAlreadySetSurcharges(list);
        //         } else {
        //             throw new Error("Fetch SeatTypes did not return a list");
        //         }
        //     } catch (err) {
        //         console.error(err);
        //     }
        // }
        fetchSeatTypesList();

        if (showId) {
            // fetchPresentSurcharges(showId);
        }
    }, [openNotification, showId]);

    return (
        <>
            {
                Array.isArray(seatTypes) ? <>
                    {
                        seatTypes.length > 0 ? <>
                            <Row gutter={20} style={{ marginBottom: 20 }}>
                                <Col sm={24}>
                                    <strong>Surcharges {selectedFormatType && ` for ${selectedFormatType?.label || selectedFormatType?.title}`}</strong>
                                </Col>
                                {
                                    !selectedFormatType ? <>
                                        <Col sm={24}>
                                            <p style={{ color: "gray" }}>Please choose a format type to edit their surcharges</p>
                                        </Col>
                                    </> : seatTypes?.map((st, i) => {
                                        // console.log({hohoh: selectedFormatType})
                                        return (
                                            <Col key={i} style={{ border: "1px solid gray", padding: 5, margin: 5, borderRadius: 5, width: 300 }}>
                                                <Flex gap={5} justify='space-between'>
                                                    <Flex gap={5} align='center'>
                                                        <div style={{ backgroundColor: st?.color, width: 20, height: 20 }}></div>
                                                        <div>{st?.title}</div>
                                                    </Flex>
                                                    <Flex flex={1} justify='center' align='center'>
                                                        <Form.Item
                                                            name={getSurchargeName(st)}
                                                            rules={[
                                                                { required: false },
                                                                { validator: validateTwoDecimalNumber }
                                                            ]}
                                                            style={{ marginBottom: 0, width: "100%" }}
                                                        >
                                                            <Input placeholder={selectedFormatType?.surcharge || 0} type='number' />
                                                        </Form.Item>
                                                    </Flex>
                                                </Flex>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </> : <>
                            <p>
                                No Seat Types added, please add some Seat Types to customise the surcharges for them.
                            </p>
                        </>
                    }
                </> : <>
                    <p style={{ color: "gray" }}>
                        Couldn't fetch Seat Types!
                    </p>
                </>
            }
        </>
    )
}

export default ProgramTypeSurchargeForm