import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { RenderActionButtons, ACTION_COLUMN_KEY } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Shows/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import dayjs from "dayjs";
import { START_TIME_FORMAT } from "Redux/ShowReducer/showSlices";
import { Button, Checkbox, Flex } from "antd";
import { Link, useParams } from "react-router-dom";

const apiUrl = "/program/rest/shows";
const headerTitle = "Shows";
const editPermission = "shows:edit";
const deletePermission = "shows:delete";
const addPermission = "shows:add";

const ShowsPage = () => {

    const params = useParams();
    const { showId } = params;

    const columns = [
        {
            title: 'Program',
            dataIndex: 'client_program',
            key: 'program',
            render: (text, row) => row?.client_program_data?.title
        },
        {
            title: 'Hall',
            dataIndex: 'hall',
            key: 'hall',
            render: (text, row) => row?.hall_data?.hall_no
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            // render: (text) => <a>{text}</a>,
            align: "right"
        },
        {
            title: 'Format Type',
            dataIndex: 'format_type',
            key: 'format_type',
            render: (text, row) => row?.format_type_data?.title,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            render: (text, row) => row?.language_data?.title
        },
        {
            title: 'Distributor',
            dataIndex: 'distributor',
            key: 'distributor',
            // render: (text) => <a>{text}</a>,
            render: (text, row) => <p>{row?.distributor_data?.name}</p>
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text) => <p>{dayjs(text, START_TIME_FORMAT).format(START_TIME_FORMAT)}</p>,
            // render: (text) => <a>{text}</a>,
            align: "right"
        },
        {
            title: 'Is Seated Event?',
            dataIndex: 'is_seated_event',
            key: 'is_seated_event',
            render: (text, row) => {
                return <Checkbox disabled checked={row.is_seated_event}></Checkbox>
            }
        },
        
        {
            title: 'Is POS Only?',
            dataIndex: 'pos_only',
            key: 'pos_only',
            render: (text, row) => {
                return <Checkbox disabled checked={row.pos_only}></Checkbox>
            }
        },
        {
            title: 'Action',
            key: ACTION_COLUMN_KEY,
            fixed: 'right',
            width: 270,
            render: (text, row, index) => {
                let show_id = showId;
                // console.log({ show_id });
                if (showId && showId.includes("undefined")) {
                    show_id = row.id;
                } else {
                    show_id = row.id;
                }
                // console.log({ show_id });
                return (<Flex vertical style={{ gap: 5 }}>
                    <Flex gap={5}>
                        <Link to={`/shows/${show_id}/pricelayout`}>
                            <Button size="small">
                                Price Layout
                            </Button>
                        </Link>
                        <Link to={`/book-show/${row.client_program}/seats/${show_id}`}>
                            <Button size="small">
                                Book Ticket
                            </Button>
                        </Link>
                    </Flex>
                    <RenderActionButtons
                        text={text}
                        row={row}
                        index={index}
                        apiUrl={apiUrl}
                        editPermission={editPermission}
                        deletePermission={deletePermission} />
                </Flex>);
            },
        },
        // ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
    ]

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
            filters={[allowedFiltersEnum.CLIENT.client_program__client, allowedFiltersEnum.THEATRE.hall__theatre]}
        />
    </>;
}

export default ShowsPage;