import { hideCreateShowDrawer, resetShowsData } from 'Redux/ShowReducer/showSlices';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Drawer, Flex, Modal, notification, Forml, Form, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PosterAndData from './PosterAndData'
import DateSelection from './DateSelection'
import Theatres from './Theatres'
import { asyncStatuses } from 'Redux/enums';
import AdditionalShowRelatedDetails from './AdditionalShowRelatedDetails';
import FinalEditModal from './FinalEditModal';
import { fetchSeatTypeList } from 'Api/commonApis';

const CreateShowDrawer = () => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();
    const saveShowsDataRequestStatus = useSelector(store => store.shows.saveShowsDataRequestStatus);
    const saveShowsDataRequestError = useSelector(store => store.shows.saveShowsDataRequestError);
    const isCreateShowDrawerVisible = useSelector(store => store.shows?.isCreateShowDrawerVisible);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [finalEditModalOpen, setFinalEditModalOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [discarding, setDiscarding] = useState(false);

    const [formatTypeOptions, setFormatTypeOptions] = useState(null);
    const [languagesOptions, setLanguagesOptions] = useState(null);
    const [distributorOptions, setDistributorsOptions] = useState(null);

    const [newlyCreatedShows, setNewlyCreatedShows] = useState({});
    const [selectedFormatType, setSelectedFormatType] = useState("");
    const [seatTypes, setSeatTypes] = useState(null);

    useEffect(()=>{
        const fetchSeatTypesList = async () => {
            try {
                const list = await fetchSeatTypeList();
                console.log({ list });
                if (Array.isArray(list)) {
                    setSeatTypes(list);
                } else {
                    throw new Error("Fetch SeatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Seat Type Options", err.message, "error");
                } else {
                    openNotification("Seat Type Options", "Something went wrong while fetching format type options", "error")
                }
            }
        }

        fetchSeatTypesList();
    }, [])

    const openNotification = useCallback((message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    }, [notificationApi]);

    useEffect(() => {
        if (saveShowsDataRequestStatus === asyncStatuses.FAILED) {
            openNotification("Error occurred", saveShowsDataRequestError, "error");
        } else if (saveShowsDataRequestStatus === asyncStatuses.SUCCESS) {
            openNotification("Saved", null, "success");
            dispatch(hideCreateShowDrawer());
            dispatch(resetShowsData());
            form.resetFields();
        }
    }, [saveShowsDataRequestStatus, dispatch, form, openNotification, saveShowsDataRequestError]);

    const closeThisDrawer = (callback) => {
        modal.confirm({
            title: 'Discard',
            icon: <ExclamationCircleOutlined />,
            content: 'This will discard all the shows selected until now. Are you sure?',
            okText: 'Yes, discard.',
            okType: 'danger',
            cancelText: 'No.',
            onOk() {
                dispatch(hideCreateShowDrawer());
                dispatch(resetShowsData());

                if (typeof callback === "function") {
                    callback();
                }
            },
            onCancel() {
                // console.log('Cancel');

            },
        });
    }

    const showFinalEditModal = (val) => {
        setFormData(val);
        setFinalEditModalOpen(true);
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title={`Create Shows`}
                width={"70vw"}
                style={{
                    minWidth: "70vw"
                }}
                onClose={closeThisDrawer}
                open={isCreateShowDrawerVisible}
                styles={{
                    body: {
                        padding: 0,
                        paddingBottom: 20,
                    },
                }}
            >
                <Flex vertical gap={20} style={{ overflow: "auto", padding: 20 }}>
                    <PosterAndData />
                    <DateSelection />
                    <Theatres />
                    <AdditionalShowRelatedDetails
                        onSave={showFinalEditModal}
                        form={form}
                        formatTypeOptions={formatTypeOptions}
                        setFormatTypeOptions={setFormatTypeOptions}
                        languagesOptions={languagesOptions}
                        setLanguagesOptions={setLanguagesOptions}
                        distributorOptions={distributorOptions}
                        setDistributorsOptions={setDistributorsOptions}
                        newlyCreatedShows={newlyCreatedShows}
                        setNewlyCreatedShows={setNewlyCreatedShows}
                        selectedFormatType={selectedFormatType}
                        setSelectedFormatType={setSelectedFormatType}
                    />
                </Flex>
            </Drawer>
            {modalContextHolder}
            <FinalEditModal 
                finalEditModalOpen={finalEditModalOpen} 
                setFinalEditModalOpen={setFinalEditModalOpen} 
                formData={formData} 
                closeThisDrawer={closeThisDrawer} 
                discarding={discarding} 
                languagesOptions={languagesOptions}
                distributorOptions={distributorOptions}
                formatTypeOptions={formatTypeOptions}
                newlyCreatedShows={newlyCreatedShows}
                setNewlyCreatedShows={setNewlyCreatedShows}
                selectedFormatType={selectedFormatType}
                setSelectedFormatType={setSelectedFormatType}
                seatTypes={seatTypes}
                setSeatTypes={setSeatTypes}
            />
        </>
    )
}

export default CreateShowDrawer