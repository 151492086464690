import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { DatePicker, Flex, Spin, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from './ExportXlsx';
const { RangePicker } = DatePicker;

const API_URL = "/reports/theatre-performance-comparison-report/";

// const HEADING_MAP = {
//     occupancy: "Occupancy",
//     totalTicketSales: "Total Ticket Sales",
//     totalFoodSales: "Total Food Sales",
//     totalSales: "Total Sales",
//     numberOfTicketsSold: "Number Of Tickets Sold",
//     itemsSoldPerTransaction: "Items Sold Per Transaction",
//     itemsSoldPerCustomer: "Items Sold Per Customer",
// }

const HEADING_MAP = [];
HEADING_MAP.push(["occupancy", "Occupancy", "percent"]);
HEADING_MAP.push(["numberOfTicketsSold", "Number Of Tickets Sold"]);
HEADING_MAP.push(["totalTicketsScanned", "Total Tickets Scanned"]);
HEADING_MAP.push(["itemsSoldPerTransaction", "Items Sold Per Transaction"]);
HEADING_MAP.push(["itemsSoldPerCustomer", "Items Sold Per Customer"]);
HEADING_MAP.push(["totalTicketSales", "Total Ticket Sales", "amount"]);
HEADING_MAP.push(["totalFoodSales", "Total Food Sales", "amount"]);
HEADING_MAP.push(["totalSales", "Total Sales", "amount"]);
HEADING_MAP.push(["refundedAmount", "Total Refunded Amount", "amount"]);

function getKeyIndex(map, keyToFind) {
    let index = 0;
    for (let key of map) {
        if (key[0] === keyToFind) {
            return index;
        }
        index++;
    }
    return -1;
}

const initialDates = dayjs();

const getProcessedData = (d) => {
    let rows = Object.keys(d[0]).filter(r => r !== "theatreTitle")
        .sort((a, b) => {
            if (getKeyIndex(HEADING_MAP, a) > getKeyIndex(HEADING_MAP, b)) {
                return 1;
            }
            return -1;
        });
    let data = [];
    for (let row of rows) {
        let rowHeading = HEADING_MAP.find(a => a[0] === row);
        if (Array.isArray(rowHeading)) {
            let newRow = {
                description: rowHeading[1]
            }
            for (let _d of d) {
                if (rowHeading[2]) {
                    if (rowHeading[2] === "amount") {
                        newRow[_d["theatreTitle"]] = getFormattedAmount(_d[row]);
                    } else if (rowHeading[2] === "percent") {
                        newRow[_d["theatreTitle"]] = Number(_d[row]).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + " %";
                    }
                } else {
                    newRow[_d["theatreTitle"]] = _d[row];
                }
            }
            data.push(newRow);
        }

    }
    console.log({ data, d });
    return data;
}

const TheatrePerformanceComparisonReport = () => {

    const [data, setData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const payload = {}
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) {
                    let data_ = response.data.data;
                    if (data_.length > 0) {
                        const columns = [{
                            title: '',
                            dataIndex: 'description',
                            key: 'description'
                        }];
                        data_.forEach(d => columns.push({ title: d.theatreTitle, dataIndex: d.theatreTitle, key: d.theatreTitle }));
                        setTableColumns(columns);
                        let processedData = getProcessedData(data_);
                        // console.log({processedData})
                        setData(processedData);
                    }
                }
                else {
                    setData([]);
                    setTableColumns([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Theatre Performance Comparison Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, openNotification]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'theatre_performance_comparison_report'}
                    reportTitleForNotification={'Theatre Performance Comparison Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%" }}>
                    <ReportTitle title={`Theatre Performance Comparison Report`} />
                    <Flex gap={15}>
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex flex={1} style={{ width: "100%" }}>
                    {
                        loading ? <Flex align='center' justify='center' style={{ width: "100%" }}> <Spin /></Flex> : (data.length <= 0 || tableColumns.length <= 0) ? <Flex align='center' justify='center' style={{ width: "100%" }}>
                            <span style={{ color: "gray" }}><em>No Data</em></span></Flex>
                            :
                            <Table
                                columns={tableColumns}
                                bordered
                                dataSource={data}
                                size='small'
                                scrollToFirstRowOnChange
                                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                                loading={loading}
                                scroll={{
                                    y: "65vh",
                                    x: "100%"
                                }}
                                pagination={false}
                            />
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default TheatrePerformanceComparisonReport