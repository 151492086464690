import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import ProgramView from "Components/ProgramView/ProgramView";
import FormDecider from "Components/Forms/Programs/Client/FormDecider";
import { Button, Flex, Image, notification } from "antd";
import { landscapePlaceholder } from "Assets";
import { ACTION_COLUMN_KEY, RenderActionButtons, RenderCreated, RenderUpdated, getCommonColumns } from "Components/CustomTable/Commons";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Link } from "react-router-dom";
import { fetchClientProgramDataAsync, hideCreateShowDrawer, resetRetrieveStatus, resetShowsData, setSelectedProgramData, showCreateShowDrawer } from "Redux/ShowReducer/showSlices";
import CreateShowDrawer from "Components/CreateShow/CreateShowDrawer";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import { useEffect } from "react";
import { asyncStatuses } from "Redux/enums";

const apiUrl = "/program/rest/client-programs";
const headerTitle = "Programs";
const editPermission = "programs:edit";
const deletePermission = "programs:delete";
const addPermission = "programs:add";

const columns = [
    {
        title: 'Poster Image',
        dataIndex: 'poster_image',
        key: 'poster_image',
        render: (text, row) => {
            let imageStyle = { width: 100 };
            if (text) return <Image src={text} fallback={landscapePlaceholder} style={imageStyle} />;
            let tbdm_json = row?.tbdm_json;
            // console.log({tbdm_json, row})
            if (typeof tbdm_json === "object") {
                if (tbdm_json && "poster_path" in tbdm_json) {
                    return <Image src={`https://media.themoviedb.org/t/p/w300_and_h450_bestv2${tbdm_json.poster_path}`} alt={`Preview ${row.title}`} fallback={landscapePlaceholder} style={imageStyle} />
                }
            }
        }
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Release Date',
        dataIndex: 'release_date',
        key: 'release_date',
        // render: (text) => <a>{text}</a>,
        align: "right"
    },
    {
        title: 'Runtime',
        dataIndex: 'runtime',
        key: 'runtime',
        // render: (text) => <a>{text}</a>,
        align: "right"
    },
    {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        align: "right"
        // render: (text) => <a>{text}</a>,
    },
    // ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: false, updated: false, action: true }),
    {
        title: 'Action',
        key: ACTION_COLUMN_KEY,
        fixed: 'right',
        width: 290,
        render: (text, row, index) => {
            return <ClientProgramActionColumn text={text} row={row} index={index} />;
        },
    },
]

const ClientProgramActionColumn = (props) => {

    const { text, row, index } = props;
    const dispatch = useDispatch();

    const selectedClient = useSelector(store => store.shows?.selectedClient);

    const onCreateShowClick = () => {
        dispatch(fetchClientProgramDataAsync({ id: row.id }));
        dispatch(showCreateShowDrawer());
    }

    return (
        <Flex style={{ gap: 5 }}>
            <Button disabled={selectedClient ? false : true} onClick={onCreateShowClick} size="small">
                Create Shows
            </Button>
            <RenderActionButtons
                text={text}
                row={row}
                index={index}
                apiUrl={apiUrl}
                editPermission={editPermission}
                deletePermission={deletePermission}
            />
        </Flex>
    );
}

const ClientProgramsPage = () => {

    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    const retrieveStatus = useSelector(store => store.shows.retrieveStatus);
    const retrieveErrorMsg = useSelector(store => store.shows.retrieveErrorMsg);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        if (retrieveStatus === asyncStatuses.FAILED) {
            openNotification("Error occurred", retrieveErrorMsg, "error");
            dispatch(resetRetrieveStatus());
            dispatch(hideCreateShowDrawer());
            dispatch(resetShowsData());
        }
    }, [retrieveStatus]);

    return <>
        {contextHolder}
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            MyFormComponent={FormDecider}
            ViewComponent={ProgramView}
            formCompononentStyle={{ width: "55vw" }}
            nonViewFormCompononentStyle={{ width: "55vw" }}
            addPermissionNames={[addPermission]}
            filters={[allowedFiltersEnum.CLIENT.client, allowedFiltersEnum.FOR_CLIENTPROGRAM.boolean]}
        />
        <CreateShowDrawer />
    </>;
}

export default ClientProgramsPage;