import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount, getFormattedNumber } from 'Utils/textFormat';
import { Badge, DatePicker, Dropdown, Flex, Space, Spin, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import ExportXlsx from './ExportXlsx';
const { RangePicker } = DatePicker;

const API_URL = "/reports/loyalty-usage-data-report/";
const initialDates = dayjs();

const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Visits',
        dataIndex: 'visits',
        width: 90,
        key: 'visits',
        align: "right",
        sorter: (a, b) => a.visits - b.visits,
        defaultSortOrder: 'descend'
    },
    {
        title: 'Ticket',
        children: [
            {
                title: 'Qty',
                dataIndex: 'ticket_quantity',
                key: 'ticket_quantity',
                align: "right",
                width: 90,
                // render: (text) => getFormattedNumber(text),
                // defaultSortOrder: 'descend',
                sorter: (a, b) => a.ticket_quantity - b.ticket_quantity,
            },
            {
                title: 'Amount',
                dataIndex: 'ticket_amount',
                key: 'ticket_amount',
                align: "right",
                render: (text) => getFormattedAmount(text),
                // defaultSortOrder: 'descend',
                sorter: (a, b) => a.ticket_amount - b.ticket_amount,
            },
        ],
    },
    {
        title: 'Food',
        children: [
            {
                title: 'Qty',
                dataIndex: 'food_quantity',
                key: 'food_quantity',
                align: "right",
                width: 90,
                sorter: (a, b) => a.food_quantity - b.food_quantity,
                // render: (text) => getFormattedNumber(text),
                // defaultSortOrder: 'descend',
            },
            {
                title: 'Amount',
                dataIndex: 'food_amount',
                key: 'food_amount',
                align: "right",
                render: (text) => getFormattedAmount(text),
                sorter: (a, b) => a.food_amount - b.food_amount,
            },
        ],
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: "right",
        sorter: (a, b) => a.total - b.total,
        render: (text) => getFormattedAmount(text),
        // defaultSortOrder: 'descend'
    }
]

const LoyaltyUsageDataReport = () => {

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const payload = {}
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data?.map((a, index) => ({ ...a, key: index })));
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Loyalty/Usage Data Report. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        if (startDate && endDate) fetchData();
    }, [startDate, endDate]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'loyalty_usage_report'}
                    reportTitleForNotification={'Loyalty/Usage Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`Loyalty/Usage Data Report`} />
                    <Flex gap={15}>
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>
                <Table
                    columns={tableColumns}
                    bordered
                    dataSource={data}
                    size='small'
                    scrollToFirstRowOnChange
                    stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                    loading={loading}
                    scroll={{
                        y: "65vh",
                        x: "100%"
                    }}
                    pagination={false}
                    expandable={{
                        // expandedRowRender: (record) => (
                        //     <p
                        //         style={{
                        //             margin: 0,
                        //         }}
                        //     >
                        //         {record.id}
                        //     </p>
                        // ),
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                        columnWidth: 40,
                        expandedRowRender: (record) => <ExpandedRowRender record={record} startDate={startDate} endDate={endDate} />,
                    }}
                />
            </Flex>
        </>
    )
}

const ExpandedRowRender = ({ record, startDate, endDate }) => {

    const [loading, setLoading] = useState(false);
    const [foodData, setFoodData] = useState(null);
    const [movieData, setMovieData] = useState(null);

    const openNotification = useNotificationContext();

    const foodColumns = [
        {
            title: 'Food Item',
            dataIndex: 'food__title',
            key: 'food__title',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => getFormattedAmount(text),
        },
    ];

    const movieColumns = [
        {
            title: 'Movie',
            dataIndex: 'order__show__client_program__title',
            key: 'order__show__client_program__title',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => getFormattedAmount(text),
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const payload = {}
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                payload['uid'] = record.id;
                // console.log(payload)
                const response = await axios.post("/reports/loyalty-usage-data-report-user/", payload);
                console.log({ response });
                if (Array.isArray(response?.data?.data?.movies)) {
                    let _movie_data = response.data.data.movies.map(m => ({ ...m, key: m.order__show__client_program__title }))
                    setMovieData(_movie_data);
                } else {
                    setMovieData(null);
                    openNotification("Data Format Unrecognized For Movies", null, "error");
                }
                if (Array.isArray(response?.data?.data?.food)) {
                    let _food_data = response.data.data.food.map(f => ({ ...f, key: f.food__title }))
                    setFoodData(_food_data);
                } else {
                    setFoodData(null);
                    openNotification("Data Format Unrecognized For Food", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Loyalty/Usage Data Report. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [startDate, endDate, openNotification, record.id]);


    return <Flex key={record.name} vertical style={{ padding: 8, paddingLeft: 40, backgroundColor: "#fff4f3" }}>
        <Flex>
            <p>Username: {record.name}</p>
        </Flex>
        {
            loading ? <Flex justify='center' align='center' style={{ height: "10vh", width: "10vh" }}>
                <Spin size='small' />
            </Flex> : <Flex justify='start' align='start' gap={15}>
                <Table columns={foodColumns} dataSource={foodData} size='small' pagination={false} />
                <Table columns={movieColumns} dataSource={movieData} size='small' pagination={false} />
            </Flex>
        }
    </Flex>;
};


export default LoyaltyUsageDataReport