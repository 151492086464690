import React, { useEffect, useState } from 'react'
import { Flex, Select, notification } from 'antd';
import { useNavigate } from 'react-router';
import allowedFiltersEnum from 'Utils/allowedFiltersEnum';
import TheatreFilter from './TheatreFilter';
import ClientFilter from './ClientFilter';
import UserFilter from './UserFilter';
import ModelNameFilter from './ModelNameFilter';
import NotificationTypeFilter from './NotificationTypeFilter';
import RoleCodeFilter from './RoleCodeFilter';
import ClientProgramBooleanFilters from './ClientProgramBooleanFilters';

const { Option } = Select;

const Filters = ({ filters, apiUrl, extraApiParamsState, setExtraApiParamsState }) => {

    const [notificationApi, contextHolder] = notification.useNotification();

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const [filtersAreValid, setFiltersAreValid] = useState(false);

    useEffect(() => {
        // check if filters are valid and set the state.
        // filters are valid only if they are from the enum
        if (Array.isArray(filters)) {
            let enumKeys = Object.keys(allowedFiltersEnum);
            let filtersAreValid = true;
            for (let f of filters) {
                let model = f.model;
                if (enumKeys.includes(model)) {
                    // no issue
                } else {
                    filtersAreValid = false;
                    break;
                }
            }
            setFiltersAreValid(filtersAreValid);
        }
    }, [filters]);
    console.log({ filtersAreValid, filters, al: allowedFiltersEnum.USER.model })

    if (!filtersAreValid) {
        return <></>;
    }

    return (
        <>
            {contextHolder}
            <Flex align='center' justify='center' gap={5}>
                {
                    filters.map((passedFilterObject, index) => {
                        if (passedFilterObject.model === allowedFiltersEnum.THEATRE.model) {
                            return <TheatreFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.CLIENT.model) {
                            return <ClientFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.USER.model) {
                            return <UserFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.MODELS.model) {
                            return <ModelNameFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.NOTIFICATION.model) {
                            return <NotificationTypeFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.ROLE.model) {
                            return <RoleCodeFilter
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        } else if (passedFilterObject.model === allowedFiltersEnum.FOR_CLIENTPROGRAM.model) {
                            return <ClientProgramBooleanFilters
                                key={index}
                                openNotification={openNotification}
                                apiUrl={apiUrl}
                                extraApiParamsState={extraApiParamsState}
                                setExtraApiParamsState={setExtraApiParamsState}
                                field={passedFilterObject.field}
                            />
                        }
                    })
                }
            </Flex>
        </>
    )
}

export default Filters