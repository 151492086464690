import { Button, Checkbox, Flex, Modal, Row, Select } from 'antd'
import { useNotificationContext } from 'Config/NotificationsContext';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { asyncStatuses } from 'Redux/enums';
import { hideCreateShowDrawer, resetShowsData, saveShowsAsync } from 'Redux/ShowReducer/showSlices';
import { getSurchargeName, surchargeNamePrefix } from './utils';

const FinalEditModal = ({
    finalEditModalOpen,
    setFinalEditModalOpen,
    formData,
    closeThisDrawer,
    discarding,
    languagesOptions,
    distributorOptions,
    formatTypeOptions,
    newlyCreatedShows,
    setNewlyCreatedShows,
    selectedFormatType,
    setSelectedFormatType,
    seatTypes,
    setSeatTypes
}) => {

    const dispatch = useDispatch();
    const openNotification = useNotificationContext();
    const allShowsData = useSelector(store => store.shows.allShowsData);
    const selectedProgramData = useSelector(store => store.shows?.selectedProgramData);

    const theatres = useSelector(store => store.shows?.selectedProgramData?.theatres);

    const saveShowsDataRequestStatus = useSelector(store => store.shows.saveShowsDataRequestStatus);

    useEffect(() => {
        console.log({ allShowsData });

        const _newlyCreatedShows = {};

        for (const date in allShowsData) {
            let newShowsOnThisDate = allShowsData[date]?.filter(show => !show.id);
            newShowsOnThisDate = newShowsOnThisDate?.map?.(ns => ({ ...ns, language: formData.language, is_pos_only: false }));
            if (newShowsOnThisDate.length > 0) {
                _newlyCreatedShows[date] = newShowsOnThisDate;
            }
        }

        console.log({ _newlyCreatedShows, formData });
        setNewlyCreatedShows(_newlyCreatedShows);
    }, [allShowsData, formData]);

    useEffect(() => {
        console.log({ newlyCreatedShows });
    }, [newlyCreatedShows])

    const saveData = () => {
        console.log({ val: newlyCreatedShows });
        // return;
        if (typeof newlyCreatedShows === "object" && Object.keys(newlyCreatedShows).length > 0) {
            let surcharges = {};
            let surchargeValue = selectedFormatType?.surcharge || 0;

            if (surchargeValue > 0) {
                for (let st of seatTypes) {
                    surcharges[getSurchargeName(st)] = surchargeValue;
                }
            }

            for (let key in formData) {
                if (key.includes(surchargeNamePrefix)) {
                    if (formData[key] !== "" && formData[key]) {
                        let sVal = 0;
                        try {
                            sVal = parseFloat(formData[key]);
                            surcharges[key] = sVal;
                        } catch (e) {
                            console.error(e);
                        }
                    };
                }
            }

            dispatch(saveShowsAsync({
                data:
                {
                    ...newlyCreatedShows,
                    'format_type': formData.format_type,
                    'language': formData.language,
                    'distributor': formData.distributor,
                    'surcharges': surcharges
                }
            })).then(() => {
                setFinalEditModalOpen(false);
                dispatch(hideCreateShowDrawer());
                dispatch(resetShowsData());
            });
        } else {
            openNotification("No Data", "Please choose at least one show to proceed.", "error");
        }
    }

    const handleDiscardAll = () => {
        closeThisDrawer(() => setFinalEditModalOpen(false));
    }

    const continueEditing = () => {
        setFinalEditModalOpen(false);
    }

    const getHallNameAndTheatreName = (hall_id) => {
        let hall_name = "";
        let theatre_name = "";

        for (const theatre of theatres) {
            const hall = theatre.halls.find(t => t.id === hall_id);
            if (hall) {
                hall_name = hall.hall_no;
                theatre_name = theatre.title;
                break;
            }
        }

        return [hall_name, theatre_name];
    }

    const changeValue = ({ value, field_name }, date, show) => {
        // find the relevant show
        let modifiedNewlyCreatedShows = {};

        for (const _date in newlyCreatedShows) {
            if (_date === date) {
                let modifiedDateShows = newlyCreatedShows[date].map?.(_show => {
                    if ((show.time === _show.time) && (show.hall_id === _show.hall_id) && (show.client_program_id === _show.client_program_id)) {
                        _show[field_name] = value;
                    }
                    return _show;
                });
                modifiedNewlyCreatedShows[_date] = modifiedDateShows;
            } else {
                modifiedNewlyCreatedShows[_date] = JSON.parse(JSON.stringify(newlyCreatedShows[_date]));
            }
        }
        setNewlyCreatedShows(modifiedNewlyCreatedShows)
    }

    return (
        <>
            <Modal
                title={`Final Confirmation: ${selectedProgramData?.title}`}
                centered
                open={finalEditModalOpen}
                confirmLoading={discarding}
                onCancel={continueEditing}
                width={700}
                footer={[
                    <Button key="discard" loading={discarding} onClick={handleDiscardAll}>
                        Discard All
                    </Button>,
                    <Button key="continue-editing" onClick={continueEditing}>
                        Continue Editing
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={saveShowsDataRequestStatus === asyncStatuses.LOADING}
                        onClick={saveData}
                    >
                        Confirm & Save
                    </Button>,
                ]}
            >
                <Flex vertical style={{ maxHeight: "75vh" }}>
                    <span>Following shows will be created once you press [Confirm and Save].</span>
                    <span>Please review them once. You can edit the <Code>language</Code> and <Code>is POS Only?</Code> for each of the shows below.</span>
                    <Flex vertical style={{ maxHeight: "70vh", overflow: "auto", marginTop: 10, padding: 5 }}>
                        {
                            Object.keys(newlyCreatedShows || {}).length > 0 ? Object.keys(newlyCreatedShows).map(date => {

                                return (<Flex key={date} vertical style={{ borderTop: "1px solid gray", borderBottom: "1px solid gray", padding: 5 }}>
                                    <b style={{ textDecoration: "underline" }}>Date: {moment(date).format("MMMM DD, YYYY")}</b>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th align='left'>Start Time</th>
                                                <th>Theatre</th>
                                                <th>Hall</th>
                                                <th>Format</th>
                                                <th>Language</th>
                                                <th>is POS Only?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                newlyCreatedShows[date]?.map((show, index) => {
                                                    const [hall_name, theatre_name] = getHallNameAndTheatreName(show.hall_id);
                                                    const format_type = formatTypeOptions.find(ft => ft.value === formData.format_type);
                                                    return (
                                                        <tr key={`${index}-${show.hall_id}-${show.time}-${show.date}`}>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <span>{show.time}</span>
                                                                </Flex>
                                                            </td>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <span>{theatre_name}</span>
                                                                </Flex>
                                                            </td>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <span>{hall_name}</span>
                                                                </Flex>
                                                            </td>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <span>{format_type?.label}</span>
                                                                </Flex>
                                                            </td>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <Select
                                                                        showSearch
                                                                        style={{
                                                                            width: 150,
                                                                        }}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                        }
                                                                        value={show.language}
                                                                        loading={Array.isArray(languagesOptions) ? false : true}
                                                                        options={languagesOptions}
                                                                        onSelect={(value) => changeValue({ value, field_name: "language" }, date, show)}
                                                                    />
                                                                </Flex>
                                                            </td>
                                                            <td>
                                                                <Flex vertical align='center' justify='center'>
                                                                    <span><Checkbox checked={show.is_pos_only} onChange={(event) => changeValue({ value: event.target.checked, field_name: "is_pos_only" }, date, show)} /></span>
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </Flex>)
                            }) : <>No Data</>
                        }
                    </Flex>
                </Flex>
            </Modal>
        </>
    )
}

const Code = ({ children }) => <code style={{ backgroundColor: "gray", color: "white" }}>&nbsp;{children}&nbsp;</code>

export default FinalEditModal;